.d-flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}


.width-100 {
    width: 100%
}

.width-80 {
    width: 80%
}

.width-50 {
    width: 50% !important;;
}

.age-verification-page iframe {
    height: calc(100vh - 100px);
    width: 100vw;
}
