#expanded-videos-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


#expanded-videos-container .video-card-container {
    width: 33%;
}


@media only screen and (max-width: 500px) {
    #expanded-videos-container {
        width: 90%;
    }
}

@media only screen and (max-width: 768px) {
    #expanded-videos-container .video-card-container {
        width: 50%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 500px) {
    #expanded-videos-container .video-card-container {
        width: 100%;
        margin: 0 auto;
    }
}
