.tinder-swipe-button {
    flex-shrink: 0;
    /*padding: 10px;*/
    border-radius: 50%;
    border: none;
    color: #fff;
    font-size: 18px;
    background-color: #9198e5;
    -webkit-transition: .2s;
    transition: .2s;
    font-weight: bolder;
    /*width: 160px;*/
    width: 54px;
    height: 54px;
    max-width: 165px;
    min-width: fit-content;
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
}

@media only screen and (max-width: 500px) {
    .tinder-swipe-button {
        margin: 0 12px;
    }
}

.tinder-swipe-button:hover {
    transform: translateY(-3px);
}

.tinder-swipe-button-like {
    background: linear-gradient(-45deg, #aa56ff, magenta);
}

.tinder-swipe-button-dislike {
    background: linear-gradient(-45deg, #f6d165, saddlebrown)
}

.badge-primary {
    background: linear-gradient(-45deg, #f6d165, #fd9f86)
}

.badge-primary:hover {
    background: linear-gradient(-45deg, #fd9f86, #f6d165);
}

.badge-secondary {
    background: linear-gradient(-45deg, #66a6ff, #88f6fe)
}

.badge-secondary:hover {
    background: linear-gradient(-45deg, #88f6fe, #66a6ff);
    background-image: linear-gradient(-45deg, rgb(136, 246, 254), rgb(102, 166, 255));
}

.badge-tertiary {
    background: linear-gradient(-45deg, #aa56ff, #f28eff);
}

.badge-tertiary:hover {
    background: linear-gradient(-45deg, #f28eff, #aa56ff);
}


.badge {
    border-radius: 6px;
    font-size: 20px;
    color: #fff;
    border: none;
    padding: 10px !important;
    font-family: Poppins, sans-serif;
    margin-right: 10px;
    margin-bottom: 15px;
    cursor: pointer;
}

.my-range {
    border-radius: 10px;
    height: 6px;
    width: 100%;
    min-width: 80px;
    outline: none;
    -webkit-appearance: none;
}

.my-range::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    background: #DEE2E6;
    border-radius: 20px;
    border: 4px solid #F47B60;
}

.range-text {
    font-size: 16px;
    /*color: rgba(0, 0, 0, 0.8);*/
    font-weight: 600;
    vertical-align: middle;
}

@media only screen and (max-width: 500px) {
    .range-text {
        font-size: 16px;
    }
}

.search-input {
    font-size: 20px;
    color: #000;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    border: none;
    border-bottom: 2px solid #000;
    padding-left: 20px;
    background-color: transparent;
    /*color: white;;*/
}

.search-box i {
    color: #000;
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 7px;
    /*color: white;;*/
}

.category-selector-container * {
    width: 90%;
}

@media only screen and (min-width: 1200px) {
    .small-screens-only {
        display: none !important;
        visibility: none !important;
    }
}
