

.special-div {
    font-family: 'Poppins', sans-serif;
    /*color: #1D2226;*/
    text-align: center;
    max-width: 768px;
    margin: auto
}

.px-20 {
    padding-left: 20px;
    padding-right: 20px
}

.px-30 {
    padding-left: 30px;
    padding-right: 30px
}

.px-40 {
    padding-left: 40px;
    padding-right: 40px
}

.px-50 {
    padding-left: 50px;
    padding-right: 50px
}

.px-60 {
    padding-left: 60px;
    padding-right: 60px
}

.px-70 {
    padding-left: 70px;
    padding-right: 70px
}

.px-80 {
    padding-left: 80px;
    padding-right: 80px
}

.px-90 {
    padding-left: 90px;
    padding-right: 90px
}

.px-100 {
    padding-left: 100px;
    padding-right: 100px
}

.py-50 {
    padding-top: 50px;
    padding-bottom: 50px
}

.py-60 {
    padding-top: 60px;
    padding-bottom: 60px
}

.py-70 {
    padding-top: 70px;
    padding-bottom: 70px
}

.py-80 {
    padding-top: 80px;
    padding-bottom: 80px
}

.py-90 {
    padding-top: 90px;
    padding-bottom: 90px
}

.py-100 {
    padding-top: 100px;
    padding-bottom: 100px
}

.my-20 {
    margin-top: 20px;
    margin-bottom: 20px
}

.my-30 {
    margin-top: 30px;
    margin-bottom: 30px
}

.my-40 {
    margin-top: 40px;
    margin-bottom: 40px
}

.my-50 {
    margin-top: 50px;
    margin-bottom: 50px
}

.my-60 {
    margin-top: 60px;
    margin-bottom: 60px
}

.my-70 {
    margin-top: 70px;
    margin-bottom: 70px
}

.my-80 {
    margin-top: 80px;
    margin-bottom: 80px
}

.my-90 {
    margin-top: 90px;
    margin-bottom: 90px
}

.my-100 {
    margin-top: 100px;
    margin-bottom: 100px
}

.mt-20 {
    margin-top: 20px
}

.mt-30 {
    margin-top: 30px
}

.mt-40 {
    margin-top: 40px
}

.mt-50 {
    margin-top: 50px
}

.mt-60 {
    margin-top: 60px
}

.mt-70 {
    margin-top: 70px
}

.mt-80 {
    margin-top: 80px
}

.mt-90 {
    margin-top: 90px
}

.mt-100 {
    margin-top: 100px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-40 {
    margin-bottom: 40px
}

.mb-50 {
    margin-bottom: 50px
}

.mb-60 {
    margin-bottom: 60px
}

.mb-70 {
    margin-bottom: 70px
}

.mb-80 {
    margin-bottom: 80px
}

.mb-90 {
    margin-bottom: 90px
}

.mb-100 {
    margin-bottom: 100px
}

.bg-pink {
    background-color: #E393F6
}

.h-2px {
    height: 2px !important
}

.step-1 {
    width: 16.6666666667%
}

.step-2 {
    width: 33.3333333334%
}

.step-3 {
    width: 50.0000000001%
}

.step-4 {
    width: 66.6666666668%
}

.step-5 {
    width: 83.3333333335%
}

.step-6 {
    width: 100%
}

.h-100vh {
    height: 100vh
}

.banner.menu-banner {
    position: relative
}

#index {
    min-width: 100%;
    height: 100vh;
    width: 100vw;
}

.desktop {
    background: url("https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/Background-pure.jpg") no-repeat center center/cover;
    width: 100%;
    height: 100vh;
}

.desktop a {
    display: inline-block
}

.desktop a img {
    border-radius: 50px;
    border: 2px solid #E393F6
}

.desktop .phone {
    height: 80vh;
    max-height: 770px;
    min-height: 600px;
    width: 375px;
    padding: 20px 10px 15px 10px;
    border-radius: 30px;
    background-color: #312d2c;
    position: relative
}

/*.desktop .phone::before {*/
/*    content: '';*/
/*    width: 60%;*/
/*    height: 40px;*/
/*    border-bottom-right-radius: 20px;*/
/*    border-bottom-left-radius: 20px;*/
/*    background-color: #312d2c;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 50%;*/
/*    -webkit-transform: translateX(-50%);*/
/*    transform: translateX(-50%)*/
/*}*/

.desktop .phone .phone-frame {
    width: 100%;
    height: 100%;
    border-radius: 30px
}

::-webkit-scrollbar {
    width: 0px
}

.page-footer {
    font-size: 12px;
    font-weight: 400;
    color: #1D2226
}

.page-footer a {
    color: #E393F6;
    text-decoration: underline
}

.welcome .title {
    font-size: 30px
}

.welcome .title-description {
    font-size: 20px
}

.welcome .mian-content {
    min-height: calc(100vh - 250px);
    padding-bottom: 80px
}

.welcome img {
    position: absolute;
    z-index: -1
}

.welcome img.top-right {
    top: -100px;
    right: -100px
}

.welcome img.bottom-right {
    bottom: -100px;
    right: -100px
}

.welcome img.bottom-left {
    bottom: -100px;
    left: -100px
}

@media only screen and (max-width: 992px) {
    .small-screens-hidden {
        display: none;
    }
}

/*# sourceMappingURL=style.css.map */
