.m-0-auto {
    margin: 0 auto;
}

.display-none {
    display: none;
}

.object-fit-cover {
    object-fit: cover !important;
}

.cursor-pointer {
    cursor: pointer;
}

.mb-10 {
    margin-bottom: 10px;
}

.send-message-form textarea {
    background-color: rgba(59, 59, 59, 1);
}

.translate-y-3px:hover {
    transform: translateY(-3px);
}
