

.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    /*margin-left: 30px;*/
    /*margin-top: 5px;*/
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }
}

.translate-y-3:hover {
    transform: translateY(-3px);
}

.profile-link-text {
    font-weight: 600;
}
@media only screen and (max-width: 500px) {
    .profile-link-text {
        font-size: 12px;
        font-weight: 700;
    }
}

.height-100vh {
    height: 100vh;
}
