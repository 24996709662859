h1,h4{
    text-align: center;
}
#me{
    position: relative;
    /*width: 50%;*/
    margin: 0 auto;
    display: block;
    width: 200px;
    height: 200px;
}
#me video{
    position: relative !important;
}
#remote-container{
    display: flex;
}
#remote-container video{
    height: auto;
    position: relative !important;
}

#me * {
    background-color: transparent;
}
