.online {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--ion-color-primary);
}

.chat_message_wrapper ul {
    padding: 10px;
    margin: 0px;
}

.chat_message_wrapper li {
    list-style: none;
    padding-bottom: 20px;
}

.chat_message_wrapper .message_inner_wrapper {
    width: 100%;
}

.chat_message_wrapper .chat_picture {
    width: 40px;
    height: 40px;
    /*background: var(--ion-color-secondary);*/
    border-radius: 50%;
}

.chat_message_wrapper img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.time_message_section {
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 40px);
}

.time_message_section.chat_message {
    padding: 10px;
    max-width: 60%;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    color: var(--ion-color-primary-contrast);
    text-align: justify;
    font-size: 14px;
    line-height: 18px;
}

.time_message_section .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /*background-image: url("../../assets/download.png");*/
    background-position: center;
    background-size: 40px;
    background-repeat: no-repeat;
    z-index: 99;
}

.blur_image {
    -webkit-filter: blur(10px);
    filter: blur(10px);
}

img {
    border-radius: 10px;
}

.chat_time {
    color: #D1D5DA;
    font-size: 12px;
    align-self: center;
}

.incoming_msg .chat_picture {
    float: left;
    border: 2px solid #F47B60;
}

.incoming_msg .time_message_section {
    float: left;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: flex-end flex-start;
    align-items: flex-end;
}

.incoming_msg .chat_message {
    margin-top: 16px;
    float: left;
    /*background-color: rgb(56,171,252);*/
    /*background: var(--ion-color-secondary);*/
    border-bottom-left-radius: 0px;
}

.incoming_msg img {
    border-bottom-left-radius: 0px;
}

.incoming_msg .chat_time {
    float: left;
}

.outgoing_msg .chat_picture {
    float: right;
    border: 1px solid #4F3C7B;
}

.outgoing_msg .time_message_section {
    float: right;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: flex-end;
    align-items: flex-end;
}

.outgoing_msg .chat_message {
    float: right;
    background-color: rgba(56,171,252, .95);
    border-bottom-right-radius: 0px;
    padding: 10px;
    border-radius: 12px;
}

.outgoing_msg img {
    border-bottom-right-radius: 0px;
}

.chat_time {
    float: right;
    /*background-image: url("../../assets/tick.png");*/
    background-repeat: no-repeat;
    background-size: 9px;
    padding-left: 20px;
    background-position: 5px;
}


.bottom_section {
    padding: 15px 15px 15px 15px;
}

.bottom_section .message_type_area {
    width: calc(100% - 55px);
    float: left;
    background-color: #F4F6F9;
    border-radius: 25px;
}

.bottom_section input {
    height: 50px;
    float: left;
    width: calc(100% - 50px);
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent;
}

.bottom_section:focus {
    outline: none;
}

input {
    color: black;
}

input:focus {
    outline: none;
}

input:active {
    outline: none;
}

.bottom_section::placeholder {
    color: var(--ion-color-dark);
}

.bottom_section .photo {
    width: 50px;
    height: 50px;
    float: left;
    /*background-image: url("../../assets/photo.png");*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
}

.bottom_section .mesage_send {
    float: right;
    width: 50px;
    height: 50px;
    margin-left: 5px;
    /*background-image: url("../../assets/send.png");*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.chat_message {
    margin-bottom: 16px;
}

.width-75 {
    width: 75% !important;
}

.width-85 {
    width: 85% !important;
}

.underline {
    text-decoration: underline;
    text-underline-offset: 3px;
}
