.login {
    background: url(https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/login-light-bg.png) no-repeat top left/cover;
    text-align: center;
    min-height: 100vh;
    padding: 60px 0  30px 0;
    display: -ms-grid;
    display: grid;
    place-items: center;
}

.login .login-main {
    background: #fff;
}

@media (min-width: 992px) {
    .login .login-main form {
        width: 50%;
        margin: auto;
    }
}

.login .login-main .form-heading {
    font-size: 50px;
    color: rgba(206, 40, 60, 0.7);
    font-weight: 700;
    margin: 50px 0 30px 0;
}

.login .login-main .form-control {
    font-size: 22px;
    color: black;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 50px;
}

@media (min-width: 1400px) {
    .login .login-main .form-control {
        padding: 1rem;
    }
}

.login .login-main .form-control::-webkit-input-placeholder {
    opacity: 0.6;
    font-style: italic;
}

.login .login-main .form-control:-ms-input-placeholder {
    opacity: 0.6;
    font-style: italic;
}

.login .login-main .form-control::-ms-input-placeholder {
    opacity: 0.6;
    font-style: italic;
}

.login .login-main .form-control::placeholder {
    opacity: 0.6;
    font-style: italic;
}

.login .login-main .form-check-label {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 300;
}

.login .login-main .form-check-input:checked {
    background-color: #F47B60;
    border-color: #CE283C;
}

.login .login-main .login-btn {
    background: -webkit-gradient(linear, left top, right top, from(#CE283C), to(#F47B60));
    background: linear-gradient(to right, #CE283C, #F47B60);
    font-size: 26px;
    color: white;
    font-weight: 500;
    border-radius: 50px;
    text-transform: uppercase;
    border: none;
}

@media (min-width: 1400px) {
    .login .login-main .login-btn {
        padding: 1rem;
    }
}

.login .login-main .fa-caret-right {
    font-size: 25px;
    color: #fff;
    position: absolute;
    top: 30%;
    right: 20px;
}

.dark-mode .login .login-main {
    background: url(https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/login-dark.png) no-repeat center center/cover;
}

.dark-mode .login .login-main .form-heading {
    font-size: 50px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 700;
}

.dark-mode .login .login-main .form-control {
    font-size: 22px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    background: rgba(255, 255, 255, 0.15);
    border: none;
}

.dark-mode .login .login-main .form-check-label {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 300;
}

.dark-mode .login.login-alt {
    background: url(https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/login-dark-bg.png);
}

.dark-mode .login.login-alt .login-main {
    background: url(https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/login-alt-dark.png) no-repeat center center/cover;
}
