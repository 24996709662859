.hidden {
    display: none;
}

.btn-red-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#CE283C), to(#F47B60));
    background: linear-gradient(to right, #CE283C, #F47B60);
    border: transparent;
    color: #ffffff;
    transition: all 0.2s linear;
}

.btn-red-gradient:hover {
    background: linear-gradient(45deg, #CE283C, #F47B60);
    color: #ffffff;
}


.btn {
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    /*border: 2px solid #F47B60;*/
    border-radius: 24px;
    padding: 16px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.pulse {
    animation: pulse 1s ease-in infinite;
}

@keyframes pulse{
    25%  {transform: scale(0.9);}
    75%  {transform: scale(1.1);}
}
